import React, {
  useState,
  createContext,
  useRef,
  useContext,
  useEffect,
} from "react";
import { useRouter } from "next/router";
import { Dialog, Combobox } from "@headlessui/react";
import { XCircleIcon, XIcon } from "@heroicons/react/solid";
import { useDebounce } from "@uidotdev/usehooks";
import { useSearchEntries, useSearchFilms } from "@/actions/searchActions";
import * as Text from "@/components/text";
import classNames from "classnames";
import Link from "next/link";

export const SearchContext = createContext(null);

export default function SearchBar() {
  const [input, setInput] = useState("");
  const router = useRouter();
  const consumer = useContext(SearchContext);
  const inputRef = useRef(null);

  const [selectedEntry, setSelectedEntry] = useState(null);

  useEffect(() => {
    if (selectedEntry) {
      consumer.setDisplay(false);
      if (selectedEntry?.attributes?.filmSlug) {
        router.push(`/catalogo/${selectedEntry.attributes.filmSlug}`);
      } else {
        router.push(`/blog/${selectedEntry?.attributes?.slug}`);
      }
    }
  }, [selectedEntry]);

  const debouncedInput = useDebounce(input, 500);

  const {
    data: entries,
    error: entriesError,
    isLoading: entriesLoading,
  } = useSearchEntries(debouncedInput);
  const {
    data: films,
    error: errorFilms,
    isLoading: isLoadingFilms,
  } = useSearchFilms(debouncedInput);

  const handleSearchKey = (e) => {
    if (e.metaKey && e.key === "k") {
      consumer.setDisplay(true);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleSearchKey);
    return () => {
      document.removeEventListener("keydown", handleSearchKey);
    };
  }, []);

  useEffect(() => {
    if (consumer.display && inputRef.current) {
      inputRef.current.focus();
    }
  }, [consumer.display]);

  return (
    <Dialog
      open={consumer.display}
      onClose={() => consumer.setDisplay(false)}
      className="relative z-50"
    >
      <Dialog.Panel className="relative">
        <div
          className="fixed inset-0 bg-black/50 backdrop-blur-sm"
          aria-hidden="true"
        />
        <div className="fixed inset-0 flex justify-center p-4">
          <XIcon
            onClick={() => consumer.setDisplay(false)}
            className="absolute right-5 top-5 z-30 h-5 w-5 cursor-pointer text-white hover:text-slate-600 md:hidden"
          />
          <div className="mt-10 block h-14 w-full rounded-lg bg-white md:h-14 md:w-1/2 ">
            <Combobox
              as="div"
              className="relative flex  w-full items-center justify-center "
              value={selectedEntry}
              onChange={setSelectedEntry}
            >
              <Combobox.Input
                placeholder="Buscar en Ambulante"
                onChange={(e) => setInput(e.target.value)}
                className=" mx-2 w-full border-transparent text-[1.3rem] leading-[2.25rem] tracking-[-0.008rem] outline-none  focus:border-transparent focus:ring-0 lg:text-[2rem]"
                ref={inputRef}
              />
              <XCircleIcon
                onClick={() => consumer.setDisplay(false)}
                className="mr-3 hidden  h-5 w-5 cursor-pointer  hover:text-slate-600 md:block"
              />

              <Combobox.Options className="absolute top-full mt-2 max-h-[500px] w-full space-y-2 overflow-scroll bg-white p-5 shadow-md">
                {films?.data?.length ? (
                  <div>
                    <Text.Overline
                      className=" bg-slate-200 p-2"
                      as="p"
                      size="sm"
                    >
                      Pelīculas
                    </Text.Overline>
                  </div>
                ) : null}

                {films?.data?.length
                  ? films?.data?.map((film, index) => (
                    <Combobox.Option
                      value={film}
                      key={film?.id || index}
                      as="div"
                    >
                      {({ active, selected }) => (
                        <div
                          className={classNames(
                            active ? "bg-slate-100" : " ",
                            "grid grid-cols-4 gap-2  hover:bg-slate-100 md:grid-cols-10  md:gap-5 "
                          )}
                          value={film?.attributes?.film_name}
                          href={`/catalogo/${film?.attributes?.filmSlug}`}
                        >
                          <div className="col-span-1">
                            <img
                              alt={film?.attributes?.film_name}
                              className="aspect-square w-full rounded-md object-cover"
                              src={
                                film?.attributes?.film_cover_image?.data
                                  ?.attributes?.url
                              }
                            />
                          </div>
                          <div className="col-span-3 border-b border-gray-200 pb-2 md:col-span-9">
                            <Text.Body
                              className="font-semibold"
                              as="p"
                              size="md"
                            >
                              {film?.attributes?.film_name}
                            </Text.Body>

                            {film?.attributes?.Directores.length &&
                              film?.attributes?.Directores.map(
                                (director, index) => (
                                  <Text.Body
                                    key={index}
                                    size="md"
                                    className="line-clamp-1"
                                  >
                                    {director?.Director}
                                  </Text.Body>
                                )
                              )}
                          </div>
                        </div>
                      )}
                    </Combobox.Option>
                  ))
                  : null}

                {entries?.data?.length ? (
                  <div>
                    <Text.Overline
                      className=" bg-slate-200 p-2"
                      as="p"
                      size="sm"
                    >
                      Publicaciones
                    </Text.Overline>
                  </div>
                ) : null}

                {entries?.data?.length
                  ? entries?.data?.map((entry, index) => (
                    <Combobox.Option
                      value={entry}
                      key={entry?.id || index}
                      as="div"
                    >
                      {({ active, selected }) => (
                        <div
                          className={classNames(
                            active ? "bg-slate-100" : " ",
                            "grid grid-cols-4 gap-2  hover:bg-slate-100 md:grid-cols-10  md:gap-5 "
                          )}
                          value={entry?.attributes?.titulo}
                          href={`/blog/${entry?.attributes?.slug}`}
                        >
                          <div className="col-span-1">
                            <img
                              alt={entry?.attributes?.titulo}
                              className="aspect-square w-full rounded-md object-cover"
                              src={
                                entry?.attributes?.cover_image?.data
                                  ?.attributes?.url
                              }
                            />
                          </div>
                          <div className="col-span-3 border-b border-gray-200 pb-2 md:col-span-9">
                            {entry?.attributes?.categorias?.data[0]
                              ?.attributes?.name && (
                                <div className="inline-block">
                                  <Badge
                                    label={
                                      entry?.attributes?.categorias?.data[0]
                                        ?.attributes.name
                                    }
                                  />
                                </div>
                              )}
                            <Text.Body
                              className="font-semibold"
                              as="p"
                              size="md"
                            >
                              {entry?.attributes?.titulo}
                            </Text.Body>
                            <Text.Body size="md" className="line-clamp-1">
                              {entry?.attributes?.subtitulo}
                            </Text.Body>
                          </div>
                        </div>
                      )}
                    </Combobox.Option>
                  ))
                  : null}
              </Combobox.Options>
            </Combobox>
          </div>
        </div>
      </Dialog.Panel>
    </Dialog>
  );
}

export function Skeletons() {
  return (
    <div className="flex w-full animate-pulse flex-row items-center gap-2 rounded border border-gray-200 p-1 shadow dark:border-gray-700 md:p-2 md:pl-10">
      <svg
        className="h-12 w-12 text-gray-200 dark:text-gray-600"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        fill="currentColor"
        viewBox="0 0 640 512"
      >
        <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
      </svg>

      <div>
        <div className="mb-2 h-2.5 w-32 rounded-full bg-gray-200 dark:bg-gray-700"></div>
        <div className="h-2 w-48 rounded-full bg-gray-200 dark:bg-gray-700"></div>
      </div>
    </div>
  );
}
export function Badge({ label }) {
  return (
    <Text.Body
      as="span"
      className="flex items-center justify-center rounded-full bg-neutral-100 px-2"
      size="sm"
    >
      {label}
    </Text.Body>
  );
}
