import apiClient from "@/utils/apiClient";
import swr from "swr";
import qs from "qs";

export const useSearchEntries = (query) => {
  const filter = qs.stringify({
    populate: "*",
    filters: {
      $and: [
        {
          titulo: {
            $containsi: query,
          },
        },
        // {
        //   ediciones_giras: {
        //     name: {
        //       $eq: "2023"
        //     }
        //   }
        // }
      ],
    },
  });
  const { data, error } = swr(`/api/entradas?${filter}`, apiClient.get);
  return {
    data: data?.data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useSearchFilms = (query) => {
  const filter = qs.stringify({
    populate: "*",
    filters: {
      $or: [
        {
          film_name: { $containsi: query },
        },
        {
          Directores: {
            Director: {
              $containsi: query,
            },
          },
        },
        {
          film_original_title: { $containsi: query },
        },
      ],
      // ediciones_giras: {
      //   name: {
      //     $eq: "2023"
      //   }
      // }
    },
  });
  const { data, error } = swr(`/api/films?${filter}`, apiClient.get);
  return {
    data: data?.data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useCategories = () => {
  const q = qs.stringify({
    populate: "*",
  });
  const { data, error } = swr(`/api/categories?${q}`, apiClient.get);
  return {
    data: data?.data,
    isLoading: !error && !data,
    isError: error,
  };
};
